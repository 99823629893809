<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>No Dues Certificate</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getNoDues"
                :disabled="!valid"
                >Generate</v-btn
              >
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="downloadNoDues"
                :disabled="!isDue"
                >No Dues</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                label="Sales"
                v-model="sales"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Receipts"
                v-model="receipts"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Credit Notes"
                v-model="credits"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Balance"
                v-model="balance"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :search="search"
        :headers="headers"
        :items="invoices"
        item-key="invkey"
        class="elevation-1"
        loading-text="Retrieving data... please wait"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { aejImgData, iataImgData, signatureDD } from "../../services/logos";

export default {
  data() {
    return {
      valid: true,
      isDue: false,
      search: "",
      loading: false,
      headers: [
        { text: "Inv No.", align: "start", value: "invoicenum" },
        { text: "Inv Date", align: "start", value: "invoicedate" },
        { text: "Passenger", align: "start", value: "passenger" },
        { text: "Sales", align: "end", value: "sales" },
        { text: "Receipts", align: "end", value: "receipts" },
        { text: "Credits", align: "end", value: "credits" },
        { text: "Balance", align: "end", value: "balance" },
      ],

      invoices: [],
      pdfdata: [],
      selectedItem: {},
      selectedIndex: -1,
      customer: {},
      sales: 0,
      receipts: 0,
      credits: 0,
      balance: 0,
      ledgerkey: "",

      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      caption: "Business & Leisure Travel Management",
      address: "No.35 St.John's Road, Isleworth, Middlesex TW7 6NY",
      telno: "Tel No. 020 8560 2458",
      email: "Email: accounts@aejtravels.co.uk",
    };
  },
  mounted() {
    this.getCurrentLedgerKey();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },
    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("DD-MM-YYYY");
    },

    getCurrentLedgerKey() {
      this.ledgerkey = localStorage.getItem("LEDG_KEY");
    },

    async getNoDues() {
      if (this.validateData()) {
        this.invoices = [];
        this.pdfdata = [];
        this.sales = 0;
        this.receipts = 0;
        this.credits = 0;
        this.balance = 0;
        try {
          this.loading = true;
          const response = await api.post("/aejmis/api/outstandings", {
            startdate: this.startDate,
            enddate: this.endDate,
            ledgerkey: this.ledgerkey,
          });
          this.pdfdata = response.data;
          this.pdfdata.map(
            ({
              invkey,
              invoicenum,
              invoicedate,
              firstname,
              lastname,
              costing,
              receipt,
              creditnote,
            }) => {
              const { invamt, rcptamt, credamt, balance } = this.calcDues(
                costing,
                receipt,
                creditnote
              );
              this.invoices.push({
                invkey: invkey,
                invoicenum: invoicenum,
                invoicedate: this.dateFormat(invoicedate),
                passenger: `${firstname.trim()} ${lastname.trim()}`,
                sales: invamt,
                receipts: rcptamt,
                credits: credamt,
                balance: balance,
              });
            }
          );
          this.sales = this.sales.toFixed(2);
          this.receipts = this.receipts.toFixed(2);
          this.credits = this.credits.toFixed(2);
          this.balance = this.balance.toFixed(2);
          if (this.balance > 0) {
            this.isDue = false;
          } else {
            this.isDue = true;
          }
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },

    calcDues(costing, receipt, creditnote) {
      let invamt = 0;
      let rcptamt = 0;
      let credamt = 0;
      let balance = 0;
      costing.forEach((item) => {
        invamt = invamt + item.quantity * item.gross;
      });
      receipt.forEach((item) => {
        rcptamt =
          rcptamt +
          (item.amountpaidtype1 +
            item.amountpaidtype2 +
            item.amountpaidtype3 +
            item.amountpaidtype4 +
            item.amountpaidtype5 +
            item.amountpaidtype6 +
            item.amountpaidtype7 +
            item.amountpaidtype8);
      });

      if (creditnote != null) {
        credamt = credamt + creditnote.creditnotevalue;
      }
      this.sales = this.sales + invamt;
      this.receipts = this.receipts + rcptamt;
      this.credits = this.credits + credamt;

      balance = invamt - rcptamt - credamt;
      this.balance = this.balance + balance;

      invamt = invamt.toFixed(2);
      rcptamt = rcptamt.toFixed(2);
      credamt = credamt.toFixed(2);
      balance = balance.toFixed(2);
      return { invamt, rcptamt, credamt, balance };
    },

    downloadNoDues() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      var pageWidth = doc.internal.pageSize.getWidth();
      var pageHeight = doc.internal.pageSize.getHeight();

      doc.addImage(aejImgData, "png", 10, 10);
      doc.addImage(iataImgData, "png", pageWidth - 47, 7);

      doc.setFontSize(12).text(this.caption, 10, 42);

      let addressX = pageWidth - (doc.getTextWidth(this.address) - 6);
      doc.setFontSize(10).text(this.address, addressX, 32);

      let telnoX = pageWidth - (doc.getTextWidth(this.telno) + 11);
      doc.setFontSize(10).text(this.telno, telnoX, 37);

      let emailX = pageWidth - (doc.getTextWidth(this.email) + 11);
      doc.setFontSize(10).text(this.email, emailX, 42);

      doc.setLineWidth(0.01).line(10, 46, 200, 46);

      const header = "NO DUES CERTIFICATE";
      let headerX = (pageWidth - doc.getTextWidth(header)) / 2 - 10;
      doc.setFont("helvetica").setFontSize(16).text(header, headerX, 53);

      doc.autoTable({
        body: [
          [
            {
              content: `Date: ${moment(new Date()).format(
                "DD/MM/YYYY"
              )}\n\n${localStorage.getItem(
                "USER_NAME"
              )}\n${localStorage.getItem("ADDRESS_1")}\n${localStorage.getItem(
                "ADDRESS_2"
              )}\n${localStorage.getItem("ADDRESS_3")}\n${localStorage.getItem(
                "PIN_CODE"
              )} \n\n\n`,
              styles: {
                halign: "left",
                fontSize: 10,
              },
            },
          ],
        ],
        margin: { left: 10, top: 55 },
        theme: "plain",
      });

      const subject = `Subject: No dues certificate for the period : ${this.dateFormat(
        this.startDate
      )} to ${this.dateFormat(this.endDate)}`;
      let subjectX = (pageWidth - doc.getTextWidth(subject)) / 2 + 10;
      doc.setFont("helvetica").setFontSize(14).text(subject, subjectX, 100);

      const letterBody = `We hereby confirm that there are no dues pending from ${this.customer.clastname} and its group of companies for services rendered by us for the above period as per our records.`;

      doc.setFont("helvetica").setFontSize(12).text(letterBody, 10, 115, {
        align: "justify",
        maxWidth: "190",
      });

      doc.setFont("helvetica").setFontSize(12).text("Thanking you,", 10, 135);
      doc
        .setFont("helvetica")
        .setFontSize(12)
        .text("Yours sincerely,", 10, 145);

      doc.addImage(signatureDD, "png", 10, 155);

      doc.setFont("helvetica").setFontSize(12).text("PROPRIETOR", 10, 175);

      doc.setLineWidth(0.01).line(10, pageHeight - 15, 200, pageHeight - 15);

      // Page Numbers and Total Pages
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 1; i <= pageCount; i++) {
        const pageNum = `Page ${String(i)} of ${String(pageCount)}`;
        let pageX = pageWidth - (doc.getTextWidth(pageNum) + 12);
        doc.setPage(i);
        doc
          .setFont("helvetica")
          .setFontSize(9)
          .text(pageNum, pageX, pageHeight - 10);
      }

      // Footer Content
      const footer = "VAT No. 859 0048 13";
      doc
        .setFont("helvetica")
        .setFontSize(10)
        .text(footer, 10, pageHeight - 10);

      // doc.save(`AEJ-NODUES-${this.startDate}-${this.endDate}.pdf`);
      window.open(doc.output("bloburl"), "_blank");
    },
  },
};
</script>
